export function initFacebookSdk() {
  return new Promise((resolve) => {
    window.fbAsyncInit = function fbAsyncInit() {
      // eslint-disable-line func-names
      window.FB.init({
        appId: process.env.FACEBOOK_APP_ID,
        xfbml: false,
        version: "v2.10",
        cookie: true,
      });
      window.FB.AppEvents.logPageView();
      resolve("SDK Loaded!");
    };
    (function loadSdk(d, s, id) {
      // eslint-disable-line func-names
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}

export function facebookSignIn(options) {
  return new Promise((resolve) => {
    window.FB.login((response) => resolve(response), options);
  });
}
