let auth2;
let loadingPromise;

const createScript = () => {
  return new Promise((resolve, reject) => {
    const el = document.getElementById("auth2_script_id");
    if (!el) {
      const gplatformScript = document.createElement("script");
      gplatformScript.setAttribute(
        "src",
        "https://apis.google.com/js/platform.js?onload=onGapiLoad",
      );
      gplatformScript.setAttribute("async", true);
      gplatformScript.setAttribute("defer", "defer");
      gplatformScript.setAttribute("id", "auth2_script_id");

      document.head.appendChild(gplatformScript);
    }
    resolve();
  });
};

const onGapiLoadPromise = () => {
  return new Promise((resolve, reject) => {
    window.onGapiLoad = () => {
      window.gapi.load("client:auth2", () => {
        try {
          auth2 = window.gapi.auth2.init({
            client_id: process.env.GOOGLE_APP_ID,
            plugin_name: "MConsultingPrep",
          });
        } catch (err) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            err: "client_id missing or is incorrect, or if you added extra params maybe they are written incorrectly, did you add it to the component or plugin?",
          });
        }
        resolve(auth2);
      });
    };
  });
};

const loadingAuth2 = () => {
  if (auth2) {
    return Promise.resolve(auth2);
  }
  if (!loadingPromise) {
    loadingPromise = onGapiLoadPromise();
  }
  return loadingPromise;
};

const wrapper = (f, method) => {
  if (f) {
    return f[method]();
  }
  const err = {
    err: "Script not loaded correctly, did you added the plugin or the client_id to the component?",
  };
  return Promise.reject(err);
};

export const initGoogleSdk = () => {
  return Promise.all([loadingAuth2(), createScript()]).then((results) => {
    console.log("results", results);
    return results[0];
  });
};

export const googleSignIn = () => wrapper(auth2, "signIn");

export const googleSignOut = () => wrapper(auth2, "signOut");

export const isSignedIn = () => wrapper(auth2.isSignedIn, "get");

export const currentUser = () => wrapper(auth2.currentUser, "get");

export const grantOfflineAccess = () => wrapper(auth2, "grantOfflineAccess");
