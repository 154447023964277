import { useEffect, useState } from "react";

import { Button } from "@chakra-ui/react";
import { OnErrorFetchResponse } from "@mcp/interfaces";
import { LoginSocialRequest } from "@mcp/services/src/services";
import { useRouter } from "next/router";
import { FaFacebook, FaGoogle, FaLinkedin } from "react-icons/fa";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useMutation } from "react-query";

import { facebookSignIn, initFacebookSdk } from "@/helpers/facebook";
import { googleSignIn, initGoogleSdk } from "@/helpers/google";
import { useToast } from "@/hooks/useToast";
// import FacebookIcon from "@/icons/FacebookIcon";
// import GoogleIcon from "@/icons/GoogleIcon";
// import LinkedInIcon from "@/icons/LinkedInIcon";
import GoogleIcon from "@/icons/GoogleIcon";
import { mApi } from "@/services";

// import { ReactComponent as LinkedinIcon } from "../../public/icons/linkedin-icon.svg";

function SocialMedia({ countryCode }: { countryCode?: string }) {
  const toast = useToast();
  const router = useRouter();

  const loginFbMutation = useMutation(
    "loginSocialControllerLoginFacebook",
    (data: LoginSocialRequest) => mApi.v1.loginSocialControllerLoginFacebook(data),
  );
  const loginGgMutation = useMutation(
    "loginSocialControllerLoginFacebook",
    (data: LoginSocialRequest) => mApi.v1.loginSocialControllerLoginGoogle(data),
  );
  const loginLinkedInMutation = useMutation(
    "loginSocialControllerLoginLinkedin",
    (data: LoginSocialRequest) => mApi.v1.loginSocialControllerLoginLinkedin(data),
  );

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.LINKEDIN_APP_ID as string,
    redirectUri: `${typeof window === "object" && window.location.origin}/signin/linkedin`,
    scope: "r_liteprofile r_emailaddress",
    onSuccess: (code) => {
      const callbackUrl = decodeURIComponent((router?.query?.callback_url || process.env.CORE_URL) as string);
      loginLinkedInMutation.mutate({
        access_token: code,
        countryCode,
      }, {
        onSuccess: async () => {
          window.parent.postMessage({ type: "login", success: true }, "*"); // TODO: login with iframe
          const isExternal = router.query?.is_external;
          if (isExternal) {
            return router.push("/signin?is_external=true&logged=true");
          }
          await router.push(callbackUrl);
        },
        onError: (error: any) => {
          const err = error as OnErrorFetchResponse;
          toast({
            title: err.error?.message,
            status: "error",
          });
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [socials] = useState<any[]>([
    {
      name: "Google",
      icon: <GoogleIcon />,
      colorScheme: "gray",
    },
    // {
    //   name: "Facebook",
    //   icon: <FaFacebook />,
    //   colorScheme: "facebook",
    // },
    {
      name: "LinkedIn",
      icon: <FaLinkedin />,
      colorScheme: "linkedin",
    },
  ]);

  useEffect(() => {
    initFacebookSdk();

    initGoogleSdk()
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onLoginSocial = async (name: string) => {
    const callbackUrl = decodeURIComponent((router?.query?.callback_url || process.env.CORE_URL) as string);

    if (name === "Facebook") {
      const resLoginFb = await facebookSignIn({ scope: "email" });
      loginFbMutation.mutate({
        access_token: resLoginFb?.authResponse.accessToken,
        countryCode,
      }, {
        onSuccess: async () => {
          window.parent.postMessage({ type: "login", success: true }, "*"); // TODO: login with iframe
          const isExternal = router.query?.is_external;
          if (isExternal) {
            return router.push("/signin?is_external=true&logged=true");
          }
          await router.push(callbackUrl);
        },
        onError: (error: any) => {
          const err = error as OnErrorFetchResponse;
          toast({
            title: err.error?.message,
            status: "error",
          });
        },
      });
    }
    if (name === "Google") {
      const resLoginGg = await googleSignIn();
      loginGgMutation.mutate({
        access_token: resLoginGg.getAuthResponse().id_token,
        countryCode,
      }, {
        onSuccess: async () => {
          window.parent.postMessage({ type: "login", success: true }, "*"); // TODO: login with iframe
          const isExternal = router.query?.is_external;
          if (isExternal) {
            return router.push("/signin?is_external=true&logged=true");
          }
          await router.push(callbackUrl);
        },
        onError: (error: any) => {
          const err = error as OnErrorFetchResponse;
          toast({
            title: err.error?.message,
            status: "error",
          });
        },
      });
    }

    linkedInLogin();
  };

  return (
    <>
      {socials.map((social: any, index) => (
        <Button
          key={social.name}
          leftIcon={social.icon}
          onClick={() => onLoginSocial(social.name)}
          mt={4}
          size="lg"
          w="full"
          colorScheme={social?.colorScheme}
        >
          <span>
            {`Sign in with ${social.name}`}
          </span>
        </Button>
      ))}
    </>
  );
}

export default SocialMedia;
