import { useToast as useToastChakra } from "@chakra-ui/react";
import { UseToastOptions } from "@chakra-ui/toast/dist/declarations/src/use-toast";

export const useToast = () => {
  const toastChakra = useToastChakra();

  return (props: UseToastOptions) => {
    return toastChakra({
      duration: 3000,
      isClosable: true,
      position: "top",
      ...props,
    });
  };
};
